import React, {useMemo} from 'react';
import StatusBadge from "../../../../components/StatusBadge";
import Loading from "../../../../components/Loading";
import InformationLine from "../../../../components/InformationLine";
import {useQuery} from "react-query";
import {apollo} from "../../../../utils/apollo";
import {GET_SLASHDATA_POLICY_MEMBERS} from "../../../../utils/graphql";
import {Link} from "react-router-dom";
import InformationColumn from "../../../../components/InformationColumn";
import ButtonInternalLink from "../../../../components/ButtonInternalLink";

function SlashdataPolicyView({loading, policy}) {
    const policyAlreadyExists = policy?.policiable?.integrationStatus === 'already_exists';
    const highlightTextColor = policyAlreadyExists ? 'text-blue-700' : null;

    if (loading || !policy) return (<Loading/>);

    return (<div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mt-4">
                <InformationColumn title="Policy Uuid" value={policy?.policyUuid}/>
                {/*<InformationColumn title="Status" value={<StatusBadge status={policy?.status}/>}/>*/}
                {/*<InformationColumn title="id" value={policy?.policiable?.id}/>*/}
                <InformationColumn title="Ic Request Ref No" value={policy?.policiable?.icRequestRefNo}/>
                <InformationColumn title="Policy Number" value={policy?.policiable?.policyNumber}/>
                <InformationColumn title="Policy Issue Date" value={policy?.policiable?.policyIssueDate}
                                   textColor={highlightTextColor}/>
                <InformationColumn title="Policy Start Date" value={policy?.policiable?.policyStartDate}
                                   textColor={highlightTextColor}/>
                <InformationColumn title="Policy Expiry Date" value={policy?.policiable?.policyExpiryDate}
                                   textColor={highlightTextColor}/>
                <InformationColumn title="Policy Type" value={policy?.policiable?.policyType}/>
                <InformationColumn title="Policy Owner Type" value={policy?.policiable?.policyOwnerType}/>
                <InformationColumn title="Policy Owner Name" value={policy?.policiable?.policyOwnerName}/>
                <InformationColumn title="Policy Owner Id" value={policy?.policiable?.policyOwnerId}/>
                <InformationColumn title="Plan Name" value={policy?.policiable?.planName}/>
                <InformationColumn title="Operation Type" value={policy?.policiable?.operationType}/>
                <InformationColumn title="Policy Creation Ref No" value={policy?.policiable?.policyCreationRefNo}
                                   textColor={highlightTextColor}/>
                <InformationColumn title="Policy Cancellation Ref No"
                                   value={policy?.policiable?.policyCancellationRefNo}/>
                <InformationColumn title="Cancellation Date" value={policy?.policiable?.cancellationDate}/>
                <InformationColumn title="Integration Status"
                                   value={<StatusBadge status={policy?.policiable?.integrationStatus}/>}/>
            </div>
            {policyAlreadyExists && <div>
                <div
                    className="flex flex-col md:flex-row gap-4 my-4 border border-blue-200 bg-blue-100 p-4 rounded text-sm text-blue-800">
                    The highlighted blue text represents values extracted from the Slashdata platform, which are
                    associated with an existing policy.
                </div>
            </div>}
            <SlashdataPolicyMembers policy={policy} key={policy?.policyUuid}/>
            {/*{fetchPolicyMembers.isLoading && <Loading/>}*/}
            {/*{fetchPolicyMembers.isSuccess && !!members?.length &&*/}
            {/*    <TransactionList members={members}/>}*/}
        </dl>
    </div>);
}

function SlashdataPolicyMembers({policy}) {

    const fetchSlashdataPolicyMembers = useQuery({
        queryKey: 'slashdata_policy_member_list', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_SLASHDATA_POLICY_MEMBERS, variables: {
                    policyUuid: policy.policyUuid,
                },
            });
            return data?.getSlashdataPolicyMembers;
        },
        keepPreviousData: false
    });

    const members = useMemo(() => fetchSlashdataPolicyMembers?.isSuccess ? fetchSlashdataPolicyMembers?.data : null, [fetchSlashdataPolicyMembers]);

    if (!members?.length) return null;
    return <div className="mt-8">
        <h3 className="mt-4 text-lg font-semibold">Policy Members</h3>
        <div className="mt-4 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                                <span className="sr-only">Edit</span>
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                FullNameEn
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                IntegrationStatus
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Status
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Errors
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                FirstNameEn
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MiddleNameEn
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                LastNameEn
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                FullNameAr
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                FirstNameAr
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MiddleNameAr
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                LastNameAr
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                EnrolmentIssueDate
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                EnrolmentStartDate
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                UnifiedNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                EmiratesIdNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                VisaFileNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                BirthCertNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                PassportNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Gender
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                NationalityCode
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                DateOfBirth
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MaritalStatus
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                RelationWithSponsor
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MemberIdType
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MemberType
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                SponsorIdNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                SponsorIdType
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MembershipCardNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                ClassName
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                SalaryBand
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                OccupationDesc
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                EmiratesOfVisaCode
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                EmiratesOfLivingCode
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MemberCreationRefNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                MemberCancelRefNo
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                CancellationDate
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                CreatedAt
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                UpdatedAt
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {members.map((member) => (
                            <tr key={member.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                    {member.integrationStatus !== 'AlreadyExists' &&
                                        <ButtonInternalLink href={`/member/edit/slashdata/${member?.memberRefNo}`}
                                                            className="text-primary-600 hover:text-primary-900">
                                            Edit<span className="sr-only">, {member.name}</span>
                                        </ButtonInternalLink>}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.fullNameEn}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <StatusBadge status={member.integrationStatus}/>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.status}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.errors}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.firstNameEn}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.middleNameEn}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.lastNameEn}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.fullNameAr}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.firstNameAr}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.middleNameAr}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.lastNameAr}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.enrolmentIssueDate}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.enrolmentStartDate}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.unifiedNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.emiratesIdNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.visaFileNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.birthCertNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.passportNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.gender}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.nationalityCode}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.dateOfBirth}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.maritalStatus}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.relationWithSponsor}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.memberIdType}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.memberType}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.sponsorIdNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.sponsorIdType}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.membershipCardNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.className}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.salaryBand}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.occupationDesc}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.emiratesOfVisaCode}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.emiratesOfLivingCode}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.memberCreationRefNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.memberCancelRefNo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.cancellationDate}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.createdAt}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {member.updatedAt}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}

export default SlashdataPolicyView;



