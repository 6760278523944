import React, {useMemo, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useQuery} from "react-query";
import {apollo} from "../../../utils/apollo";
import {GET_FILE_IMPORT_STATUS_COUNT, GET_INTEGRATION_LIST} from "../../../utils/graphql";
import usePolicyStore from "../../../store/policy";
import SlashdataPolicyImportForm from "./forms/slashdata-policy-import-form";
import {FILE_IMPORT_STATUS} from "../../../utils/constants";
import Tabs from "../../../components/Tabs";
import {CheckIcon, ClockIcon, QueueListIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {cn} from "../../../utils/style";
import Loading from "../../../components/Loading";
import SelectInput from "../../../components/SelectInput";
import PolicyImportView from "./views/policy-import-view";
//
// const CURRENT_DAY = `0${new Date().getDate()}`.slice(-2);
// const CURRENT_MONTH = `0${new Date().getMonth() + 1}`.slice(-2);
// const CURRENT_YEAR = new Date().getFullYear().toString();

function PolicyImport() {
    // const [date, setDate] = useState({day: CURRENT_DAY, month: CURRENT_MONTH, year: CURRENT_YEAR});

    // const queryClient = useQueryClient()
    // const navigate = useNavigate();

    const {integrationUuid, setIntegrationUuid} = usePolicyStore();

    const [activeTab, setActiveTab] = useState('overview');
    const [statusTabs, setStatusTabs] = useState(FILE_IMPORT_STATUS)

    const fetchIntegrationList = useQuery({
        queryKey: 'integration_list', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_INTEGRATION_LIST, variables: {},
            });
            return data?.getIntegrationList;
        },
        keepPreviousData: true,
        onSuccess: ({data}) => {
            if (data?.length === 1) {
                // auto select the first item if only 1 is available
                setIntegrationUuid(data[0]?.integrationUuid);
            }
        }
    })

    useQuery('file_import_status_count', async () => {
        const {data} = await apollo.query({
            query: GET_FILE_IMPORT_STATUS_COUNT, variables: {},
        });
        return data?.getFileImportStatusCount;
    }, {
        keepPreviousData: false,
        refetchInterval: 5000,
        cacheTime: 5000,
        staleTime: 5000,
        onSettled: (data) => {
            if (data && Array.isArray(data)) {
                let updatedStatusTabs = [...statusTabs];
                data.forEach((item) => {
                    let foundIndex = updatedStatusTabs.findIndex(tab => tab.column === item.status);
                    if (foundIndex !== -1) {
                        updatedStatusTabs[foundIndex].count = parseInt(item.count);
                    }
                });
                setStatusTabs(updatedStatusTabs);
            }
        },
    });

    const integrationList = useMemo(() => fetchIntegrationList?.isSuccess ? fetchIntegrationList?.data?.data : null, [fetchIntegrationList]);
    const integration = useMemo(() => {
        const l = integrationList?.filter(i => i.integrationUuid === integrationUuid);
        return l && l.length > 0 ? l[0] : null;
    }, [integrationUuid, integrationList]);


    return (<DashboardLayout>
        <PageHeader title="Member Bulk Upload" description="Import policies from external sources.">
            <Tabs hideOnMobile={false} tabs={statusTabs} rightSide={true} get={activeTab} set={v => setActiveTab(v)}/>
        </PageHeader>
        {activeTab === 'overview' && <div>
            <div className="mt-6">
                <div className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4 sm:grid")}>
                    <label
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Integration <span className="text-red-500">*</span>
                    </label>
                    <div className="relative p-0.5 mt-2 sm:col-span-2 sm:mt-0 overflow-hidden">
                        <div className="max-w-xs">
                            <SelectInput name="integration_list" id="integration_list" get={integrationUuid}
                                         set={setIntegrationUuid}>
                                <option value=""></option>
                                {integrationList && integrationList?.map((integration) => (
                                    <option key={integration?.integrationUuid}
                                            value={integration?.integrationUuid}>{`${integration?.name} | ${integration?.integrationUuid}`}</option>))}
                            </SelectInput>
                        </div>
                    </div>
                </div>

                {integrationList?.isLoading && <Loading/>}

                {(!integrationList?.isLoading && !integration) &&
                    <div className="flex items-center justify-center h-40 text-gray-500 font-light text-xl">
                        Select integration to continue.
                    </div>}

                {integration?.integrationType === 'slashdata' &&
                    <SlashdataPolicyImportForm integration={integration}/>}
            </div>
            <FileImportCardsOverview statusTabs={statusTabs}/>
        </div>}

        {['processing', 'completed', 'failed'].includes(activeTab) &&
            <PolicyImportView key={activeTab} filterStatus={activeTab}/>}

    </DashboardLayout>);
}

function FileImportCardsOverview({statusTabs}) {
    // todo: get stats based on day, week, month
    let status = useMemo(() => {
        let list = {
            overview: 0,
            processing: 0,
            completed: 0,
            failed: 0
        };
        statusTabs?.forEach(s => {
            list[s.column] = s?.count
        });
        return list
    }, [statusTabs]);

    return <div className="mt-12">
        <h2 className="font-semibold mb-4">Overview for uploaded files</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
            <OverviewLine icon={<QueueListIcon className="w-6"/>} title="Total uploaded files" count={status.overview}
                          color={'blue'}/>
            <OverviewLine icon={<ClockIcon className="w-6"/>} title="Under Process Files" count={status.processing}
                          color={'orange'}/>
            <OverviewLine icon={<CheckIcon className="w-6"/>} title="Processed Files" count={status.completed}
                          color={'green'}/>
            <OverviewLine icon={<XMarkIcon className="w-6"/>} title="Rejected Files" count={status.failed}
                          color={'red'}/>
        </div>
    </div>;
}

function OverviewLine({icon, title, count, color}) {

    const colors = {
        red: {
            border: 'border-4 border-double border-red-300',
            icon: 'text-red-500',
            text: 'text-red-800',
            bg: 'bg-red-50/50',
        },
        blue: {
            border: 'border-4 border-double border-blue-300',
            icon: 'text-blue-500',
            text: 'text-blue-800',
            bg: 'bg-blue-50/50',
        },
        orange: {
            border: 'border-4 border-double border-orange-300',
            icon: 'text-orange-500',
            text: 'text-orange-800',
            bg: 'bg-orange-50/50',
        },
        green: {
            border: 'border-4 border-double border-green-300',
            icon: 'text-green-500',
            text: 'text-green-800',
            bg: 'bg-green-50/50',
        },
        default: {
            border: 'border-4 border-double border-gray-300',
            icon: 'text-gray-500',
            text: 'text-gray-800',
            bg: 'bg-gray-50/50',
        }
    };
    const colorScheme = colors[color] || colors.default;

    return <div className={cn(
        `flex shrink-0 flex-col justify-center items-center bg-gray-100 rounded-lg px-4 py-8`,
        colorScheme.border, colorScheme.bg
    )}>
        <div className={cn(`mb-3`, colorScheme.icon)}>{icon}</div>
        <div className={cn(`text-sm font-semibold`, colorScheme.text)}>{title}</div>
        <div className={cn(`text-2xl`, colorScheme.icon)}>{count}</div>
    </div>;
}

export default PolicyImport;
