import React from 'react';
import {cn} from "../utils/style";

function InformationColumn({title, value, defaultValue, textColor}) {
	return <div>
		<dt className={cn("text-sm font-medium leading-6 text-gray-900", textColor)}>{title}</dt>
		<dd className={cn("mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0", textColor)}>
			{value || defaultValue || <span className="text-gray-400">N/A</span>}
		</dd>
	</div>
}

export default InformationColumn;