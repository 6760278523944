import React, {useMemo} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from "react-query";
import {CANCEL_POLICY, GET_POLICY_DETAILS} from "../../../utils/graphql";
import {apollo} from "../../../utils/apollo";
import SlashdataPolicyView from "./views/slashdata-policy-view";
import ButtonLink from "../../../components/ButtonLink";
import Button from "../../../components/Button";
import StatusBadge from "../../../components/StatusBadge";
import InformationColumn from "../../../components/InformationColumn";
// import InformationLine from "../../../components/InformationLine";
// import ProductsDetails from "../../../components/ProductsDetails";
// import MoneyHighlight from "../../../components/MoneyHighlight";
// import InvoiceField from "../../../components/InvoiceField";
// import TransactionList from "../../../TransactionList";

function PolicyView() {
    const {policy_uuid} = useParams();

    const fetchPolicy = useQuery({
        queryKey: 'policy_view', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_POLICY_DETAILS, variables: {
                    policyUuid: policy_uuid,
                },
            });
            return data?.getPolicyDetails;
        }, keepPreviousData: false
    });

    const cancelPolicyMutation = useMutation({
        mutationKey: 'cancel_policy', mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: CANCEL_POLICY, variables: {
                    policyUuid: policy_uuid
                },
            });
            return data?.cancelPolicy;
        }, onSuccess: (canceled) => {
            if (canceled === true) {
                fetchPolicy.refetch();
            }
        }
    })

    const policy = useMemo(() => fetchPolicy?.isSuccess ? fetchPolicy?.data : null, [fetchPolicy]);
    // const members = useMemo(() => fetchPolicyMembers?.isSuccess ? fetchPolicyMembers?.data : null, [fetchPolicyMembers]);

    const handleCancelPolicy = () => {
        if (window.confirm('Are you sure you want to cancel this policy?')) {
            cancelPolicyMutation.mutate();
        }
    }

    return (<DashboardLayout>
        <PageHeader back="/policies" title="Policy View"
                    description="View and manage your policies">
            <InformationColumn title="Status" value={<StatusBadge status={policy?.status}/>}/>
        </PageHeader>

        <SlashdataPolicyView key={policy_uuid} loading={fetchPolicy?.isLoading} policy={policy}/>

        <div className="flex my-6 gap-4 items-center">
            {['completed'].includes(policy?.status) &&
                <Button onClick={handleCancelPolicy}
                        className="from-red-500 to-red-600 hover:from-red-400 hover:to-red-500">Cancel Policy</Button>}
        </div>

    </DashboardLayout>);
}

export default PolicyView;



